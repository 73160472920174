/*###########################################################################
// Search results
//#########################################################################*/

const searchResults = {
	config: {
		searchField: '.search-field'
	},

	elements: {},

	init() {
		const self = this

		self.elements.searchFields = document.querySelectorAll(self.config.searchField)

		if (self.elements.searchFields !== null) {
			for (let i = 0; i < self.elements.searchFields.length; i++) {
				const searchField = self.elements.searchFields[i]

				self.handleSearchResults(searchField)
			}
		}
	},

	handleSearchResults(searchField) {
		const searchInput = document.getElementById('search');
		const searchButton = document.querySelector('.search-field button[type="submit"]');

		// Check value on init
		if (searchInput.value.trim().length > 0) {
			searchButton.classList.add('has-text')
		} else {
			searchButton.classList.remove('has-text')
		}

		searchInput.addEventListener('keyup', function () {
			const self = this

			//check value on keyup
			if (self.value.trim().length > 0) {
				searchButton.classList.add('has-text')
			} else {
				searchButton.classList.remove('has-text')
			}
		});

		searchButton.addEventListener("click", function() {
			if(searchButton.classList.contains('has-text')){
				searchInput.value = ""
				searchButton.classList.remove('has-text')
			} else {
				searchInput.focus()
			}
		})


	}
}

window.addEventListener('load', function () {
	searchResults.init()
})
