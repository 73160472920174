/*###########################################################################
// Sprig filter
//#########################################################################*/

import { scrollTo } from "../utilities/_scrollTo"

const sprigFilter = {
  config: {
    sprigFilter: '.sprigFilter',
		sprigFilterControls: '.sprigFilter__controls'
  },

  elements: {
    sprigFilter: null,
  },

  init() {
    const self = this

    self.elements.sprigFilter = document.querySelector(self.config.sprigFilter)
		self.elements.sprigFilterControls = document.querySelector(self.config.sprigFilterControls)

    if (self.elements.sprigFilter !== null) {
      if (typeof htmx !== 'undefined') {
        // Add loading state
        //
        htmx.on('htmx:beforeRequest', function (event) {
          document.querySelector('#entry-container').classList.add('is-loading')

					if (self.elements.sprigFilterControls !== null) {
						self.elements.sprigFilterControls.classList.add('is-loading')
						self.elements.sprigFilterControls.style.pointerEvents = 'none'
					}
        })

        // After swap re-add event listeners for the above and remove loading state
        //
        htmx.on('htmx:afterSwap', function (event) {
          document.querySelector('#entry-container').classList.remove('is-loading')

					if (self.elements.sprigFilterControls !== null) {
						self.elements.sprigFilterControls.classList.remove('is-loading')
						self.elements.sprigFilterControls.style.pointerEvents = ''
					}

					if (self.elements.sprigFilter.classList.contains('sprigFilter--scroll-to-results')) {
						// Scroll to entries
						self.scrollToContainer()
					}
        })
      }
    }
  },

  scrollToContainer() {
    const offsetAmount = document.querySelector('.header').clientHeight + 40
    scrollTo(document.querySelector('#entry-container'), -offsetAmount)
  }

}

window.addEventListener('load', function () {
  sprigFilter.init()
})
